import React, { useState } from 'react'
import { navigate } from 'gatsby'
import useAuth from '../hooks/useAuth'
import { sendForgotPassword } from '../backendApi/userApi'

const ForgotPassword = () => {
  const { state, login } = useAuth()
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    const a = 1;
      (async () => {
        console.log(a)
        const res = await sendForgotPassword(identifier)
        if (res) {
          alert('Please check your email for a reset link. It may have gone to spam. Wait a few minutes before trying again please!')
        } else {
          alert('Something went wrong. Are you sure you used this email to sign up?')
        }
    })()
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">
            Email: 
          </label>
          <input 
            onChange={ e => {
              setIdentifier(e.target.value)
            }}
            value={ identifier }
            id="username" type="text" placeholder="Username" />
        </div>
        <div>
          <button type="submit">
            Send reset link
          </button>
        </div>
      </form>
      { (error.length > 1) && (
        <p>
          { error }
        </p>
      )}
    </div>
  )
}

export default ForgotPassword
